import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import MenuDrawer from './menu-drawer'
import Footer from './footer'
import '@splidejs/splide/dist/css/splide-core.min.css'

const Layout = ({ children, pageTitle, pageClass, meta, schema }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title,
          description,
          siteUrl
        }
      }
    }
  `)

  const focusVisiblePolyfill = () => {
    const isBrowser = () => typeof window !== "undefined"

    const navKeys = ['ARROWUP', 'ARROWDOWN', 'ARROWLEFT', 'ARROWRIGHT', 'TAB', 'ENTER', 'SPACE', 'ESCAPE', 'HOME', 'END', 'PAGEUP', 'PAGEDOWN'];
    let currentFocusedElement = null;
    let mouseClick = null;

    isBrowser() && window.addEventListener('keydown', (event) => {
      if(navKeys.includes(event.code.toUpperCase())) {
        mouseClick = false;
      }
    });

    isBrowser() && window.addEventListener('mousedown', (event) => {
      mouseClick = true;
    });

    isBrowser() && window.addEventListener('focus', () => {
      if (currentFocusedElement) currentFocusedElement.classList.remove('focused');

      if (mouseClick) return;

      currentFocusedElement = document.activeElement;
      currentFocusedElement.classList.add('focused');

    }, true);
  }

  try {
    document.querySelector(":focus-visible");
  } catch {
    focusVisiblePolyfill();
  }

  const metaTitle = pageClass.includes('template-index') ? `${data.site.siteMetadata.title} | Shopify Design & Development Agency` : `${pageTitle} | Shopify Experts – ${data.site.siteMetadata.title}`;

  let pageMetaData = {
    type: 'website',
    url: `https://www.ultrafine.co`,
    title: metaTitle,
    description: data.site.siteMetadata.description,
    image: `${data.site.siteMetadata.siteUrl}/ultrafine-logo.jpg`
  }

  // Override with page-specific metadata when provided
  if (meta !== undefined) {
    for (let property in pageMetaData) {
      if (pageMetaData.hasOwnProperty(property)) {
        if (meta[property] !== undefined) {
          pageMetaData[property] = meta[property];
        }
      }
    }
  }

  return (
    <div className={pageClass}>
      <Helmet>
        <html lang="en" className={`html-${pageClass}`} />
        <title>{metaTitle}</title>
        <meta name="description" content={pageMetaData.description} />
        <script defer data-domain="ultrafine.co" src="https://plausible.io/js/plausible.js"></script>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${data.site.siteMetadata.siteUrl}",
                "name": "${data.site.siteMetadata.title}",
                "description": "${data.site.siteMetadata.description}",
                "logo": "${data.site.siteMetadata.siteUrl}/ultrafine-logo.jpg",
                "image": "${data.site.siteMetadata.siteUrl}/ultrafine-logo.jpg",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "hello@ultrafine.co",
                  "contactType": "Client services"
                }
              }
          `}
        </script>
        {pageClass.includes('template-index') &&
          <script type="application/ld+json">
            {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "url": "${data.site.siteMetadata.siteUrl}",
                  "name": "${data.site.siteMetadata.title}"
                }
            `}
          </script>
        }

        {schema &&
          <script type="application/ld+json">
            {schema}
          </script>
        }

        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:url" content={pageMetaData.url} />
        <meta property="og:title" content={pageMetaData.title} />
        <meta property="og:type" content={pageMetaData.type} />
        <meta property="og:description" content={pageMetaData.description}></meta>
        <meta property="og:image" content={pageMetaData.image} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageMetaData.title} />
        <meta name="twitter:description" content={pageMetaData.description}></meta>
        <meta name="twitter:image" content={pageMetaData.image} />

        <link rel="stylesheet" href="/font-tracking.css" media="print" />

        <link rel="icon" type="image/png" href={`${data.site.siteMetadata.siteUrl}/favicon-32x32.png`} sizes="32x32" />
        <link rel="icon" type="image/png" href={`${data.site.siteMetadata.siteUrl}/favicon-16x16.png`} sizes="16x16" />
      </Helmet>
      <a className="skip-to-content-link button visually-hidden" href="#MainContent">Skip to content</a>
      <header className="header">
        <div className="container">
          <div className="header__grid grid">
            <div className="header__grid-left header__logo">
              <Link to="/" className="header__logo-link">
                
                <svg className="header__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 613.79 161.17">
                  <path d="m36.3,16.24v54.12c0,2.9.11,5.87.33,8.91.22,3.04.88,5.79,1.98,8.25,1.1,2.46,2.84,4.47,5.21,6.01,2.38,1.54,5.72,2.31,10.03,2.31s7.63-.77,9.97-2.31c2.33-1.54,4.07-3.54,5.21-6.01,1.14-2.46,1.83-5.21,2.05-8.25.22-3.04.33-6.01.33-8.91V16.24h25.74v57.68c0,15.49-3.54,26.8-10.63,33.92-7.08,7.13-17.98,10.69-32.67,10.69s-25.61-3.56-32.74-10.69-10.69-18.43-10.69-33.92V16.24h25.87Z" fill="#1d1d1d"/>
                  <path d="m139.65,6.2v109.56h-23.89V6.2h23.89Z" fill="#1d1d1d"/>
                  <path d="m182.68,67.98v47.78h-23.89v-47.78h-7.92v-19.93h7.92v-20.33h23.89v20.33h13.6v19.93h-13.6Z" fill="#1d1d1d"/>
                  <path d="m205.25,48.05h23.89v11.09c2.55-4.05,5.68-7.15,9.37-9.31,3.7-2.16,8.01-3.23,12.94-3.23h2.05c.75,0,1.6.09,2.57.26v22.84c-3.17-1.58-6.6-2.38-10.3-2.38-5.54,0-9.7,1.65-12.47,4.95-2.77,3.3-4.16,8.12-4.16,14.45v29.04h-23.89V48.05Z" fill="#1d1d1d"/>
                  <path d="m319.83,48.05h24.02v67.72h-24.02v-7.52c-5.1,6.42-12.01,9.64-20.72,9.64-4.93,0-9.46-.9-13.6-2.71-4.14-1.8-7.74-4.33-10.82-7.59-3.08-3.25-5.48-7.08-7.19-11.48-1.72-4.4-2.57-9.2-2.57-14.39,0-4.84.83-9.44,2.51-13.79,1.67-4.36,4-8.16,7-11.42,2.99-3.25,6.55-5.83,10.69-7.72,4.13-1.89,8.71-2.84,13.73-2.84,8.45,0,15.44,2.95,20.99,8.84v-6.73Zm-30.1,33.66c0,2.2.4,4.25,1.19,6.14.79,1.89,1.87,3.54,3.23,4.95,1.36,1.41,2.99,2.51,4.88,3.3,1.89.79,3.94,1.19,6.14,1.19s4.11-.4,6.01-1.19c1.89-.79,3.52-1.89,4.88-3.3,1.36-1.41,2.46-3.04,3.3-4.88.83-1.85,1.25-3.83,1.25-5.94s-.42-4.11-1.25-6.01c-.84-1.89-1.94-3.54-3.3-4.95-1.37-1.41-2.99-2.51-4.88-3.3-1.89-.79-3.89-1.19-6.01-1.19s-4.25.4-6.14,1.19c-1.89.79-3.52,1.89-4.88,3.3-1.37,1.41-2.44,3.02-3.23,4.82-.79,1.8-1.19,3.76-1.19,5.87Z" fill="#1d1d1d"/>
                  <path d="m362.07,48.05v-12.54c0-4.4.94-8.56,2.84-12.47,1.89-3.91,4.44-7.33,7.66-10.23,3.21-2.9,6.95-5.21,11.22-6.93,4.27-1.72,8.78-2.57,13.53-2.57s9.26.84,13.53,2.51c4.27,1.67,8.01,3.96,11.22,6.86,3.21,2.9,5.76,6.31,7.66,10.23,1.89,3.92,2.84,8.12,2.84,12.61v3.04h-23.89v-3.04c0-1.58-.29-3.1-.86-4.55-.57-1.45-1.37-2.75-2.38-3.89-1.01-1.14-2.2-2.07-3.56-2.77-1.37-.7-2.88-1.06-4.55-1.06-3.26,0-5.96,1.23-8.12,3.7-2.16,2.46-3.23,5.33-3.23,8.58v12.54h13.73v19.93h-13.73v47.78h-23.89v-47.78h-8.58v-19.93h8.58Zm70.49,67.72h-23.89V48.05h23.89v67.72Z" fill="#1d1d1d"/>
                  <path d="m448.92,48.05h23.89v8.58c3.25-3.96,6.55-6.62,9.9-7.99,3.34-1.36,7.26-2.05,11.75-2.05,4.75,0,8.82.77,12.21,2.31,3.39,1.54,6.27,3.72,8.65,6.53,1.93,2.29,3.25,4.84,3.96,7.66.7,2.82,1.06,6.03,1.06,9.64v43.03h-23.89v-34.19c0-3.34-.24-6.05-.73-8.12-.48-2.07-1.34-3.72-2.57-4.95-1.06-1.06-2.24-1.8-3.56-2.24-1.32-.44-2.73-.66-4.22-.66-4.05,0-7.15,1.21-9.31,3.63-2.16,2.42-3.23,5.87-3.23,10.36v36.17h-23.89V48.05Z" fill="#1d1d1d"/>
                  <path d="m558.08,85.93c0,10.3,4.84,15.44,14.52,15.44,5.19,0,9.11-2.11,11.75-6.34h23.1c-4.67,15.49-16.33,23.23-34.98,23.23-5.72,0-10.96-.86-15.71-2.57s-8.82-4.16-12.21-7.33c-3.39-3.17-6.01-6.95-7.85-11.35-1.85-4.4-2.77-9.33-2.77-14.78s.88-10.71,2.64-15.25c1.76-4.53,4.27-8.38,7.52-11.55,3.25-3.17,7.19-5.61,11.81-7.33,4.62-1.72,9.83-2.57,15.64-2.57s10.87.86,15.44,2.57c4.57,1.72,8.45,4.2,11.62,7.46,3.17,3.26,5.59,7.24,7.26,11.95,1.67,4.71,2.51,10.01,2.51,15.91v2.51h-50.29Zm27.98-13.86c-.79-3.34-2.42-6.03-4.88-8.05-2.46-2.02-5.46-3.04-8.98-3.04-3.7,0-6.71.97-9.04,2.9-2.33,1.94-3.81,4.67-4.42,8.18h27.32Z" fill="#1d1d1d"/>
                </svg>
              </Link>
            </div>
            <div className="header__navigation-wrapper header__navigation-wrapper--desktop header__grid-right">
              <nav className="header__navigation">
                <ul className="list-menu list-unstyled" role="list">
                  <li className="list-menu__list-item">
                    <Link to="/work" className="list-menu__item">Featured Work</Link>
                  </li>
                  <li className="list-menu__list-item">
                    <Link to="/articles" className="list-menu__item">Articles</Link>
                  </li>
                  <li className="list-menu__list-item">
                    <Link to="/contact" className="list-menu__item">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header__navigation-wrapper header__navigation-wrapper--mobile header__grid-right">
              <MenuDrawer siteTitle={data.site.siteMetadata.title} />
            </div>
          </div>
        </div>
      </header>
      <main id="MainContent">
        {children}
      </main>
      <Footer />
    </div>
  )
}
export default Layout